import { Magic } from 'magic-sdk';
const magic = new Magic('pk_live_74C76FA18896223B');

export const isLoggedIn = async () =>{
    const res = await magic.user.isLoggedIn();
    return res;
}

export const checkUser = async (cb) => {
    const isLoggedIn = await magic.user.isLoggedIn();
    if (isLoggedIn) {
        const user = await magic.user.getMetadata();
        const idToken = await magic.user.generateIdToken({lifespan: 3600});
        return cb({ isLoggedIn: true, email: user.email, DIDToken:idToken });
    }
    return cb({ isLoggedIn: false });
};

export const loginUser = async (email) => {
    await magic.auth.loginWithMagicLink({ email });
};

export const logoutUser = async () => {
    await magic.user.logout();
};

export const preloadMagic = () => {
    magic.preload();
}