import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    Form,
    Row,
    FormControl,
    Card,
    Col,
    InputGroup,
    Container,
    Image,
    Toast
} from 'react-bootstrap';
import { CloudArrowUp } from 'react-bootstrap-icons';
import NoData from '../Assets/NoData.svg'

import FileUploadModal from './FileUploadModal.js';


function UploadCard(props) {

    const [apiValidated, setApiValidated] = useState(),
        [isApiLoading, setApiLoading] = useState(false),
        [apiValue, setApiValue] = useState(``),
        [storeItems, setStoreItems] = useState([]),
        [productValue, setProductValue] = useState(``),
        [showFileModal, setShowFileModal] = useState(),
        [selectedProduct, setSelectedProduct] = useState(false),
        [showTemplateToast, setShowTemplateToast] = useState(false),
        [showTemplateErrorToast, setShowTemplateErrorToast] = useState(false),
        [templateLoading, setTemplateLoading] = useState(false),
        [templateFile, setTemplateFile] = useState();

    //Update state on input
    const onApiInput = ({ target: { value } }) => setApiValue(value);
    const onProductChange = ({ target: { value } }) => {
        setProductValue(value);
        if(value){
            setSelectedProduct(storeItems.find(item => item.productId === value));
        }
        else{
            setSelectedProduct(false)
        }
    }

    //On API form submit attempt to search GAPI
    const onApiFormSubmit = e => {
        const form = e.currentTarget;
        setApiValidated(true);
        e.preventDefault();
        e.stopPropagation();
        if (form.checkValidity()) {
            setApiLoading(true);
            apiKeySearch();
        }
    };


    //Search GAPI for products based on API Key
    const apiKeySearch = async () => {
        try {
            let storeItemsRes = await axios.get('/api/Server', {
                params: {
                    DIDToken: props.user.DIDToken,
                    action: 'apiKeySearch',
                    apiKey: apiValue
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            if(Array.isArray(storeItemsRes.data)){
                setStoreItems(storeItemsRes.data);
            }
        } catch (error) {
            if (error.response.status === 403) {
                props.setShowLogoutToast(true);
            }
        }
        setApiLoading(false);
    }

    //Set product options based on returned values
    const productOptions = () => {
        let items;
        items = storeItems.map(item =>
            <option
                key={uuidv4()}
                id={item.productId}
                value={item.productId}>
                {item.productId}: {item.name}
            </option>
        );
        items.unshift(
            <option
            key={uuidv4()}
            id={0}
            value="">
            Please Select An Item
            </option>
        )
        return items
    };

    //Get a template file from logic app and display resulting url
    const getTemplateFile = async () => {
        setTemplateLoading(true);
        axios.post('https://prod-182.westus.logic.azure.com:443/workflows/c39d9167e92f42e3887cee0067311838/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N_JI1UpvAU_G-GttgzKws1yq2Xajq1MX9enpA7JvQWI', {
            apiKey: apiValue,
            productId: productValue
        })
        .then(res => {
            setTemplateFile(
                <a href={res.data.url} target="blank">Download File</a>
            )
            setShowTemplateToast(true);
            setTemplateLoading(false);
            })
        .catch(error => {
            console.log(error);
            setTemplateLoading(false);
            setShowTemplateErrorToast(true);
        })
    }



    return (
        <Container className="my-5 mx-auto">
            <Card className="shadow-sm bg-white text-center">
                <Card.Header className="text-left">
                    <CloudArrowUp className="mb-1 mr-2"></CloudArrowUp>Uploader
                </Card.Header>
                <Card.Body className="p-0">
                    <Row className="w-100 mx-auto">
                        <Col xs={12} md={7} className="px-4">
                            <Card.Title className="py-3">
                                <h3 className="font-weight-bold text-left">Batch Offline Order Processor</h3>
                            </Card.Title>
                            <p className="lead mb-0 mt-0 text-left">Search for a customer by Api Key and select a product</p>
                            <hr></hr>
                            <Row className="my-4">
                                <Col>
                                    <Form noValidate validated={apiValidated} onSubmit={onApiFormSubmit} className="text-left mt-4">
                                        <Form.Group className="mb-0" controlId="formBasicEmail">
                                            <Form.Label><strong>Step 1:</strong> Store Search</Form.Label>
                                            <InputGroup className="mb-0">
                                                <Form.Control
                                                    required
                                                    placeholder="Api Key"
                                                    onChange={onApiInput}
                                                    value={apiValue}
                                                    pattern="^\d{6}$"
                                                    className="mx-auto"
                                                />
                                                <InputGroup.Append>
                                                    <Button
                                                        variant="dark"
                                                        type="submit"
                                                        className="text-white font-weight-bold ml-2"
                                                        disabled={isApiLoading}
                                                    >
                                                        {isApiLoading ? 'Loading...' : 'Search 🔎'}
                                                    </Button>
                                                </InputGroup.Append>
                                                <FormControl.Feedback type="invalid">
                                                    Api Key is required
                                                </FormControl.Feedback>
                                            </InputGroup>
                                    </Form.Group>
                                    </Form>
                                    <p className="lead text-left text-muted mb-0"><small>{(storeItems.length > 0) ? String(storeItems.length + ' items found') : ''}</small></p>
                                    <Form noValidate className="text-left mt-4">
                                    <Form.Group controlId="itemSelect">
                                        <Form.Label><strong>Step 2:</strong> Product Selector</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                as="select" 
                                                custom
                                                onChange={onProductChange}
                                                value={productValue}
                                                required={true}
                                            >
                                                {(storeItems.length < 1) ? <option disabled>Please search for a store</option> : productOptions()}
                                            </Form.Control>
                                            <InputGroup.Append>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={5} className="bg-white text-white py-3">
                            <p className="text-dark"><strong>Step 3:</strong> Get a template file, or upload orders</p>
                            <Row className="py-3 justify-content-center">
                                <Button 
                                    disabled={(!selectedProduct || templateLoading ? true: false)} 
                                    variant="info" 
                                    className="mx-3 rounded-0 text-white" 
                                    size="lg"  
                                    onClick={() => getTemplateFile()}>
                                    {templateLoading ? 'Loading...' :  'Get Template File 📂'}
                                </Button>
                            </Row>
                            <Row className="py-3 justify-content-center">
                                <Button 
                                    disabled={(!selectedProduct? true: false)} 
                                    onClick={() => setShowFileModal(true)} 
                                    variant="primary" 
                                    className="mx-3 rounded-0" 
                                    size="lg">
                                    Upload Excel File 📤
                                </Button>
                            </Row>
                            <Row className="text-center justify-content-center">
                                <Col>
                                    <h5 className="my-3 font-weight-light text-dark">
                                        {selectedProduct === false ? 'Select An Api Key and Product' : String(selectedProduct.productId + ': ' + selectedProduct.name)}
                                    </h5>
                                    <Image
                                        style={{maxHeight: 200}}
                                        className="img-fluid mx-auto"
                                        src={selectedProduct ? selectedProduct.imageURL : NoData}
                                    >
                                    </Image>
                                </Col>                        
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <FileUploadModal
                setShowLogoutToast={props.setShowLogoutToast}
                user={props.user}
                apiValue={apiValue}
                productValue={productValue}
                showFileModal={showFileModal}
                setShowFileModal={setShowFileModal}
            ></FileUploadModal>

            <Toast
                style={{
                position: 'absolute',
                bottom: '1%',
                right: '1%',
                minWidth: 350
                }}
                show={showTemplateToast}
                onClose={() => setShowTemplateToast(false)}
            >
                <Toast.Header className="bg-dark text-white">
                    ⚡ <strong className="ml-1 mr-auto">B.O.O.P.</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className="bg-white">
                    <p className="lead text-center">Your Template File Is Ready!</p>
                    <p className="lead font-weight-normal">{templateFile}</p>
                    <p className="text-muted">
                        Please note: Billing variables must be manually added as columns with this format:
                        <br></br>
                        <strong>bv_YourBillingVariableLabel</strong>
                    </p>
                </Toast.Body>
            </Toast>

            
            <Toast
                style={{
                position: 'absolute',
                bottom: '1%',
                right: '1%',
                minWidth: 350
                }}
                show={showTemplateErrorToast}
                onClose={() => setShowTemplateErrorToast(false)}
                autohide
                delay={8000}
            >
                <Toast.Header className="bg-danger text-white">
                    ⚡ <strong className="ml-1 mr-auto">B.O.O.P.</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className="bg-white">
                    <p className="font-weight-bold text-center">
                        There was an error fetching your template
                    </p>
                    <p className="font-weight-light">
                        Please try again, if the error persists reach out to the SA team with the product/api key you are attempting to fetch a template file for.
                    </p>
                </Toast.Body>
            </Toast>

        </Container>
    )
};


export default UploadCard;
