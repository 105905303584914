import React, { useState } from 'react';
import axios from 'axios';
import { loginUser, preloadMagic } from '../magic.js';
import Lottie from 'lottie-react-web';
import FactoryAnimation from '../Assets/FactoryAnimationLottie.json';

import {
    Button,
    Form,
    Container,
    Row,
    Image,
    FormControl,
    Col
} from 'react-bootstrap';

import Logo from '../Assets/PFL-Logo-Color.png';




function Login(props) {

    preloadMagic();//Preload Magic Auth Iframe

    const [validated, setValidated] = useState(),
        [isPreapproved, setPreapproved] = useState(),
        [isLoading, setLoading] = useState(false),
        [loginServerError, setLoginServerError] = useState(),
        [value, setValue] = useState(``);//State for this component

    const onInput = ({ target: { value } }) => setValue(value);//On form input update state

    const onFormSubmit = e => {//On form submit, validate and attempt login
        const form = e.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (form.checkValidity() === true) {
            e.preventDefault();
            setLoading(true);
            checkUserPreapproval(value)
        }
    };

    async function checkUserPreapproval(email) {//Checks a user's preapproval to access the dashboard
        if (isPreapproved === false) {
            setPreapproved(null);
        }
        if(loginServerError === true){
            setLoginServerError(false);
        }
        try {
            await axios.get('/api/Server', {//Get user pre-approval
                params: {
                    action: 'checkUser',
                    email: email
                }
            })
            userLogin(email);
        } catch (error) {//show error
            console.log(error.response.status);
            if(error.response.status === 500){
                setLoginServerError(true)
            }
            else if(error.response.status === 403){
                setPreapproved(false);
            }
            setLoading(false);
        }
    };

    async function userLogin(email) {
        try {
            await loginUser(email);
            props.validateUser();
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <Row className="mx-auto w-100 h-100 text-center scribble-row">
            <Col md={6} className="h-100 d-flex bg-white">
                <Container className="my-auto w-100">
                    <Image
                        src={Logo}
                        style={{ width: '100%', maxWidth: '20rem' }}
                    ></Image>
                    <h1 className="mb-0 py-5 display-5 font-weight-bold" style={{ color: '#000' }}>Login to the B.O.O.P.</h1>
                    <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="sr-only">PFL Email address</Form.Label>
                            <Form.Control
                                required
                                placeholder="hello@pfl.com"
                                onChange={onInput}
                                value={value}
                                style={formControl}
                                pattern="^.+?@pfl.com$"
                                className="mx-auto"
                            />
                            <FormControl.Feedback
                                type="invalid"
                            >
                                PFL Email Address is required to access this dashboard
                                </FormControl.Feedback>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            size="lg"
                            style={{ maxWidth: 350 }}
                            className="text-white font-weight-bold w-100"
                            disabled={isLoading || props.loggingIn}
                        >
                            {isLoading ? 'Loading...' : 'Log in'}
                        </Button>
                    </Form>
                    {loginServerError ? <p className="text-danger"><small>There was an issue logging you in, please try again</small></p> : null}
                    {isPreapproved === false ? <p className="text-danger"><small>Your email is not authorized to access this dashboard</small></p> : null}
                    {props.loggingIn === true ? <p className="text-primary"><small>Logging in, please wait...</small></p> : null}
                </Container>
            </Col>
            <Col className="h-100 d-none d-md-flex bg-primary" md={6}>
                <Lottie
                    options={{
                        animationData: FactoryAnimation,
                        speed: 0.8
                    }}
                />
            </Col>
        </Row >
    )
};

const formControl = {
    border: '1px solid black',
    maxWidth: 350
}


export default Login;
