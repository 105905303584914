import React, {useState} from "react";
import {
    Navbar,
    Button,
    Toast,
    Container
} from 'react-bootstrap';
import { logoutUser } from '../magic.js';

import UploadCard from './UploadCard';


function Uploader(props) {

    const [showLogoutToast, setShowLogoutToast] = useState(false);

    function logout() {
        logoutUser();
        props.setUser({ isLoggedIn: false });
    }

    function logoutClose(){
        setShowLogoutToast(false)
        logout()
    }
    
    return (
        <>
            <Navbar bg="black" className="w-100" variant="dark">
                <Container>
                    <Navbar.Brand>
                        ⚡
                        <strong>B.O.O.P.</strong>
                    </Navbar.Brand>
                    <Button
                        variant="outline-light"
                        className="ml-auto font-weight-bold rounded-0"
                        onClick={logout}
                    >
                        Log Out
                    </Button>            
                </Container>
            </Navbar>
                <UploadCard
                    user={props.user}
                    setShowLogoutToast={setShowLogoutToast}
                ></UploadCard>
            <Toast
                    show={showLogoutToast}
                    delay={2000} 
                    autohide
                    onClose={() => logoutClose()}
                    style={{
                        position: 'absolute',
                        bottom: '1.5%',
                        left: '1%',
                    }}
                >
                    <Toast.Header className="bg-dark text-white">
                        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                        <strong className="mr-auto">B.O.O.P</strong>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body>Whoops, you're not authorized to view this page, logging you out now...</Toast.Body>
                </Toast>
        </>
    )
}

export default Uploader;