import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { FileDrop } from 'react-file-drop';
import AddFile from '../Assets/AddFile.svg';

import {
    Modal,
    Button,
    Row,
    Container,
    Toast
} from 'react-bootstrap';

function FileUploadModal(props) {

    const [fileToUpload, setFileToUpload] = useState(false),
        [isValidFile, setValidFile] = useState(false),
        [isProcessedFile, setProcessedFile] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [uploadError, setUploadError] = useState(false),
        [showToast, setShowToast] = useState(false);


    const fileInputRef = useRef(null);

    const onFileInputChange = (event) => {
        const { files } = event.target;
        setFileToUpload(files[0]);
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onFileDrop = (event) => {
        setFileToUpload(event[0]);
    }

    useEffect(() => {
        if(fileToUpload !== false){
            checkValidFile();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fileToUpload]);


    const checkValidFile = () => {
        var fname = fileToUpload.name;
        var re = /(\.xlsx)$/i;
        if (!re.exec(fname)) {
            setValidFile(false);
            setProcessedFile(false);
            setFileToUpload(false);
            setShowToast(true);
        }
        else{
            setShowToast(false);
            setValidFile(true);
        }
    }


    const uploadFile = async () => {
        setIsLoading(true);
        //File
        //let file = fileInputRef.current.files[0];
        let file = fileToUpload
        //Read File
        const reader = new FileReader();
        reader.onload = async function(e) {
            let readFile = e.target.result;
            axios.post('./api/Server?action=uploadFile&DIDToken='+props.user.DIDToken, {
                fileData: readFile,
                userEmail: props.user.email,
                apiValue: props.apiValue,
                productValue: props.productValue
            })
            .then(res => {
                setProcessedFile(true);
                setUploadError(false);
                setFileToUpload(false);
                setIsLoading();
                setValidFile(null);
                setShowToast(true)
            })
            .catch(error => {
                setUploadError(true);
                setIsLoading(false);
                setShowToast(true);
            })
          };
          reader.readAsDataURL(file);
    }

    const generateToast = () => {

        const toastHeaderClass = `text-white ` + (isValidFile === false || uploadError === true ? `bg-danger ` : ``) + (isProcessedFile === true ? `bg-success ` : ``)
        const toastBodyStyle = {
            backgroundColor: (isValidFile === false || uploadError === true ? `#fae1e3` : ``)
        }
        const toastMessage = () => {
                if (isValidFile === false){
                    return (`You've selected an invalid file, please try again`)
                }
                if (uploadError === true){
                    return (`Error uploading your file, please try again`)
                }
                if (isProcessedFile === true){
                    return (`Upload succeeded, please check the Command Center for order status`)
                }
            }
        

        return(
            <>
                <Toast
                    onClose={() => {
                        setShowToast(false);
                        }
                    }
                    show={showToast}
                    delay={5500}
                    autohide
                    style={{
                        position: 'absolute',
                        top: '1%',
                        right: '1%',
                    }}
                >
                    <Toast.Header className={toastHeaderClass}>
                        <strong className="mr-auto">B.O.O.P</strong>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body style={toastBodyStyle}>{toastMessage()}</Toast.Body>
                </Toast>
            </>
        )
    }

    const buttonDisabled = () => {
        if((isValidFile === true || 
            isProcessedFile === true ||
            uploadError === true)
            && !isLoading
            && fileToUpload !== false
        ){
            return false
        }
        else{
            return true
        }
    }
    

    return (
        <>
            <Modal
                show={props.showFileModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => props.setShowFileModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className="border-bottom-0"
                    >
                        <p className="lead font-weight-normal mb-0">Upload Order Data</p>
                        <p className="lead text-muted"><small>xlsx files only</small></p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3 bg-light">
                    <p className="lead text-center">Drop a file or click to upload</p>
                    <div className="text-center">
                        <input
                            onChange={onFileInputChange}
                            ref={fileInputRef}
                            type="file"
                            className="d-none"
                        />
                        <FileDrop
                            target={document.querySelector('.dropModal')}
                            onTargetClick={onTargetClick}
                            onDrop={onFileDrop}
                            style={{ height: 0 }}
                        >
                            <img alt="File Upload" src={AddFile} className="dropModal py-4" style={{ width: '15rem' }}></img>
                        </FileDrop>
                    </div>
                    <Row>
                        <Container>
                            <p className="pb-0 mb-1">Selected File: <strong>{fileToUpload !== false ? String(fileToUpload.name) : 'no file selected...'}</strong></p>
                            <p className="text-muted">Please double check the format of your file to ensure it matches the product's template</p>
                        </Container>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-light border-top-0">
                    <Button
                        className="mx-auto rounded-0 text-white"
                        variant="primary"
                        size="lg"
                        disabled={buttonDisabled()}
                        onClick={() => uploadFile()}
                    >
                        {isLoading ? 'Loading...' : 'Place Orders 🚀'}
                    </Button>
                </Modal.Footer>
                {generateToast()}
            </Modal>


        </>
    );
}

export default FileUploadModal;