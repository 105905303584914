import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { checkUser, isLoggedIn } from './magic.js';
import Login from './Components/Login.js';
import Uploader from './Components/Uploader.js';

import './App.scss';

function App() {

  //user
  const [user, setUser] = useState({ isLoggedIn: null, email: null, DIDToken: null}),
    [loggingIn, setLoggingIn] = useState();

    const validateUser = async () => {//Validates a user and sets state
      try {
        await checkUser(setUser);
        setLoggingIn(false);
      } catch (error) {
        console.error(error);
      }
    }

    useEffect(() => {//Run use effect to validate user only on mount
      isLoggedIn().then((res) => {
        if(res === true){
          setLoggingIn(true);
          validateUser();
        };
      })
    }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={["/Login"]}>
            {user.isLoggedIn === false || user.isLoggedIn === null ? 
              <Login 
                user={user}
                validateUser={validateUser}
                loggingIn={loggingIn}
              ></Login>
              : <Redirect to="/Uploader"></Redirect>
            }
          </Route>
          <Route path="/Uploader">
            {user.isLoggedIn === true && user.DIDToken ? 
              <Uploader
                setUser={setUser}
                user={user}
              ></Uploader>
              : <Redirect to="/Login"></Redirect>
            }
          </Route>
          <Route>
            <Redirect to="/Login"></Redirect>
          </Route>
        </Switch>
      </Router >
    </div >
  );
}

export default App;
